/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**
 *  Custom reset
 */
html {
  box-sizing: border-box;
	font-family: 'Nunito', sans-serif;
	font-size: 10px;
}

*, *:before, *:after {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: inherit;
}

body {
	font-size: 1.6rem;
}

p {
	margin-bottom: 1rem;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #e9f4ed !important;
	border-radius: 0.3rem !important;
	color: #009068 !important;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
	background-color: #e9f4ed !important;
	border-radius: 0.3rem !important;
}

.DayPicker-Month {
	margin: 1rem 0 0 !important;
}

.DayPicker-Caption {
	margin: 0 0 1rem 0 !important;
}

.DayPicker-Day {
	padding: 1.4rem 1.1rem !important;
}

@media (min-width: 360px) {
	.DayPicker-Day {
		padding: 1.6rem 1.4rem !important;
	}
}

body.sub-page .main-nav {
	display: none;
}
